/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./styles.scss";

import React, { useRef, useEffect } from "react";
import Slider from "react-slick";

import SliderArrow from "icons/slider-arrow.svg";
import ExitIcon from "icons/exit.svg";

function CustomArrow(props) {
	const { className, onClick } = props;
	return <SliderArrow className={className} onClick={onClick} />;
}

const Backdrop = ({ images, selected_image, exitHandler }) => {
	const ref = useRef();

	const sliderSettings = {
		initialSlide: selected_image,
		dots: false,
		autoplay: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		draggable: true,
		nextArrow: <CustomArrow className="home-hero__slider--next" />,
		prevArrow: <CustomArrow className="home-hero__slider--prev" />,
	};

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, []);

	const onExit = (e) => {
		if (e.target !== e.currentTarget) return;
		exitHandler();
	};

	return (
		<div className="backdrop" onClick={onExit}>
			<div className="backdrop__box">
				<ExitIcon
					className="backdrop__box__exit"
					onClick={exitHandler}
				/>
				<Slider
					{...sliderSettings}
					ref={ref}
					className="backdrop__slider"
				>
					{images?.map(({ image }, index) => (
						<div key={index} className="backdrop__box__item">
							<img src={image?.sourceUrl} alt="" />
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default Backdrop;
