import "./styles.scss";
import React from "react";

const Info = ({ title, text, image }) => {
  return (
    <section className="location-info">
      <div className="container">
        <div className="location-info__text">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <div
          className={`location-info__image`}
          style={{ backgroundImage: `url(${image?.sourceUrl})` }}
        />
      </div>
    </section>
  );
};

export default Info;
