/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./styles.scss";

import React, { useState } from "react";

import Backdrop from "./components/Backdrop";

const Gallery = ({ variant = "", data, title }) => {
	const [selected_image, setSelectedImage] = useState(null);

	if (!!!data) return null;

	return (
		<div className={`gallery ${variant}`}>
			<div className="container">
				<h2>{title}</h2>
				<div className="gallery__items">
					{data?.map(({ image: { sourceUrl } }, index) => (
						<div
							key={index}
							className="gallery__item"
							style={{ backgroundImage: `url(${sourceUrl})` }}
							onClick={() => setSelectedImage(index)}
						/>
					))}
				</div>
			</div>
			{selected_image !== null && (
				<Backdrop
					images={data}
					selected_image={selected_image}
					exitHandler={() => setSelectedImage(null)}
				/>
			)}
		</div>
	);
};

export default Gallery;
