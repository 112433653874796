import React from "react";
import { graphql } from "gatsby";
import LazyLoad from "react-lazyload";

import { useLocale } from "context/locale";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";
import Info from "page_components/Location/Info";
import Gallery from "components/Gallery";
import MapSection from "components/MapSection";

const LocationContent = ({ location_data, map_data }) => {
  const { t } = useLocale();

  return (
    <>
      <PageHeader label={t("Localization")} />
      <Info {...location_data} />
      <LazyLoad>
        <MapSection data={map_data} withHeaderText />
      </LazyLoad>

      <Gallery
        data={location_data?.images}
        title={t("See the surroundings of the building")}
        variant="vertical"
      />
    </>
  );
};

const Location = ({ location, pageContext: { translations }, data }) => {
  const map_data = data?.location?.acfMap?.mapData;
  const location_data = data?.location?.acfLocation;
  const contact_data = data?.contact?.acfContact;

  return (
    <Layout
      seo={{
        title: data.location.seo.title || location.title,
        description: data.location.seo.metaDesc || "",
      }}
      location={location}
      translations={translations}
      contact_data={contact_data}
    >
      <LocationContent location_data={location_data} map_data={map_data} />
    </Layout>
  );
};

export default Location;

export const query = graphql`
  query ($id: Int!) {
    location: wpPage(databaseId: { eq: $id }) {
      seo {
        title
        metaDesc
      }
      acfMap {
        mapData {
          title
          distance
          description
          icon
        }
      }
      acfLocation {
        title
        text
        image {
          sourceUrl
        }
        images {
          image {
            sourceUrl
          }
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
